import './App.css';
import '@elastic/eui/dist/eui_theme_light.css';
import Editor from '@monaco-editor/react';
import axios from 'axios';
import { Suspense, useState, useRef, useEffect } from 'react';
import { EuiIcon, EuiFieldText, EuiTextArea, EuiMarkdownFormat, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, useGeneratedHtmlId, EuiFlexGroup, EuiCard, EuiCodeBlock, EuiPageTemplate, EuiProvider, EuiButton, EuiPageHeader, EuiAvatar, EuiProgress } from '@elastic/eui';
// import { css } from '@emotion/react';

function EsqlHubCard({title, description, code, id, likes, comments}) {
  return (
    <EuiCard
      textAlign="left"
      title={title}
      description={<EuiMarkdownFormat>{description}</EuiMarkdownFormat>}
    >
    {<EuiFlexGroup gutterSize='s' direction="column">
      <EuiCodeBlock language="sql" isCopyable lineNumbers>{code}</EuiCodeBlock>
      <EuiFlexGroup gutterSize='s'>
        <EuiIcon type="faceHappy" size="l" />
        <EuiIcon type="faceSad" size="l" />
        <EuiIcon type="editorComment" size="l" />
      </EuiFlexGroup>
    </EuiFlexGroup>}
    </EuiCard>
  )
}

function App() {
  // const { euiTheme } = useEuiTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const uploadQuery = async () => {
    try {
      await axios.post('https://esqlhub-kv.locknessko.com/api/upload', {
        "title": titleValue,
        "description": descriptionValue,
        "code": editorRef.current.getValue(),
        "comments": [],
        "likes": 0
      });
      getSnippets();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);
  const modalTitleId = useGeneratedHtmlId();

  const editorRef = useRef(null);
  function handleEditorDidMount(editor) {
    editorRef.current = editor;
  }
  const [titleValue, setTitleValue] = useState("");
  const onTitleChange = (e) => {
    setTitleValue(e.target.value);
  };
  const [descriptionValue, setDescriptionValue] = useState("");
  const onDescriptionChange = (e) => {
    setDescriptionValue(e.target.value);
  };

  const [snippets, setSnippets] = useState([]);
  const getSnippets = async () => {
    try {
      const response = await axios.get('https://esqlhub-kv.locknessko.com/api/snippets');
      const data = response.data;
      setSnippets(Object.keys(data).map(item => {let x = data[item]; return <EsqlHubCard
            id={x.id}
            likes={x.likes}
            comments={x.comments}
            title={x.title}
            description={x.description}
            code={x.code}
          />}));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSnippets();
  }, []);

  return (
    <EuiProvider colorMode="dark">
      {isModalVisible && (
        <EuiModal aria-labelledby={modalTitleId} style={{ width: 800 }} onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle id={modalTitleId}>
              Upload query
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFlexGroup direction='column' gutterSize='xs'>
              <EuiFieldText fullWidth placeholder="Some Query..." value={titleValue} onChange={onTitleChange} />
              <EuiTextArea fullWidth placeholder="This query does X. Analyse the results like X." value={descriptionValue} onChange={onDescriptionChange} compressed />
              <Editor height="35vh" theme="vs-dark" onMount={handleEditorDidMount} defaultLanguage="sql" defaultValue="from logs-*
| " />
            </EuiFlexGroup>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton onClick={uploadQuery} fill>
              Upload
            </EuiButton>
            <EuiButton onClick={closeModal} fill>
              Close
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
      <EuiPageTemplate>
        <EuiPageTemplate.Header>
          <EuiPageHeader
            pageTitle="ES|QL Hub"
            iconType="console"
            rightSideItems={[
              <EuiAvatar name="Raphael" />,
              <EuiButton onClick={showModal} fill>Upload Query</EuiButton>
            ]}
          />
        </EuiPageTemplate.Header>
        <Suspense fallback={
          <EuiProgress size="xs" color="accent" />
        }>
          <EuiPageTemplate.Section grow={false}>
            <EuiFlexGroup gutterSize="l" direction="column">
              {snippets}
            </EuiFlexGroup>
          </EuiPageTemplate.Section>
        </Suspense>
      </EuiPageTemplate>
    </EuiProvider>
  );
}

export default App;
